import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import MarkdownContent from '../components/MarkdownContent'
import Content from '../components/Content'
import Helmet from 'react-helmet'

// eslint-disable-next-line
export const PrivacyPolicyPageTemplate = ({
  title,
  content,
  contentComponent,
  siteTitle,
}) => {
  const PageContent = contentComponent || Content

  return (
    <section>
      <div className="container flex flex-col mb-8 md:mb-16">
        <Helmet>
          <title>{`${title} | ${siteTitle || 'Content Manager'}`}</title>
          <meta
            name="description"
            content={`The Joinery is committed to protecting and respecting your privacy. This policy sets out the basis on which any personal data we collect from you, or that you provide to us, will be processed by us.`}
          />
          <meta property="og:title" content={`${title} | ${siteTitle}`} />
          <meta
            property="og:description"
            content={`The Joinery is committed to protecting and respecting your privacy. This policy sets out the basis on which any personal data we collect from you, or that you provide to us, will be processed by us.`}
          />
        </Helmet>
        <h1 className="mt-10 mb-8 text-4xl purple-text text-purple font-bold font-playfair md:mt-20 md:text-5xl md:mb-18 lg:text-7xl lg:mb-24">
          {title}
        </h1>
        <div className="flex flex-col mb-4">
          <PageContent className="privacy-policy content" content={content} />
        </div>
      </div>
    </section>
  )
}

PrivacyPolicyPageTemplate.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.node.isRequired,
  contentComponent: PropTypes.func,
}

const PrivacyPolicyPage = ({ data }) => {
  const { markdownRemark: post } = data
  const { frontmatter } = data.markdownRemark

  return (
    <Layout>
      <PrivacyPolicyPageTemplate
        title={frontmatter.title}
        content={post.html}
        contentComponent={MarkdownContent}
        siteTitle={data.site.siteMetadata.title}
      />
    </Layout>
  )
}

PrivacyPolicyPage.propTypes = {
  data: PropTypes.object.isRequired,
}

export default PrivacyPolicyPage

export const PrivacyPolicyPageQuery = graphql`
  query PrivacyPolicyPage($id: String!) {
    site {
      siteMetadata {
        title
      }
    }
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
      }
    }
  }
`
